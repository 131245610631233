<template>
  <div class="card-header text-end">
    <img src="@/assets/bg.jpg" class="image-bg" alt="image" />
    <div>
      <i @click="setProfile" class="bi bi-gear icon"></i>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const AppName = process.env.VUE_APP_NAME;
    const setProfile = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER}`,
          external: false,
        });
      });
    };

    return {
      setProfile,
      AppName

    };
  },
});
</script>

<style scoped>
.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.image-bg {
  width: 100%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
